import { Button, Col, Form, Row } from 'react-bootstrap';
import React, { useState } from 'react';
import { PostContact } from '../../services/ContactService';
import IconAlert from '../common/IconAlert';
import { toast } from 'react-toastify';
import FormAlert from './global/FormAlert';
import quoteUsers from 'assets/img/seo-276-5-1.webp';
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import PropTypes from 'prop-types';
import qwentyLight from '../../assets/img/qwenty-light.webp';

const ContactForm = ({ questionsHistoryStat }) => {
  let leadData = {};
  if (localStorage.getItem('qwenty.lead')) {
    leadData = JSON.parse(localStorage.getItem('qwenty.lead'));
  }
  const [formData, setFormData] = useState({
    firstname: leadData.firstname || '',
    lastname: leadData.lastname || '',
    company: leadData.company || '',
    email: leadData.email || '',
    phone: leadData.phone || '',
    message: '',
    rgpd: 0,
    questionsHistoryStat: questionsHistoryStat,
    'g-recaptcha-response': false,
    context: 'formContact'
  });
  const [errorForm, setErrorForm] = useState(false);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [contactSent] = useState(false);

  const ReComponent = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleSubmit = async () => {
      try {
        setButtonIsLoading(true);
        const token = await executeRecaptcha('check_contact_form_mnspf');
        let formDataSubmit = {
          ...formData,
          'g-recaptcha-response': token
        };
        const response = await PostContact(formDataSubmit);
        if (response.validator) {
          setErrorForm(response.validator);
          document.getElementById('formcontact').scrollIntoView();
        }
        setButtonIsLoading(false);
        if (response.success === true) {
          window.location.href = 'https://qwenty.fr/merci';
        } else if (!response.validator) {
          toast('Une erreur est survenue, veuillez réessayer plus tard');
        }
      } catch (err) {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };

    return (
      <div>
        <GoogleReCaptcha />
        <Button
          size="sm"
          variant="falcon-default blue lead-submit"
          className="me-2 mt-3 mt-lg-1"
          style={{ borderWidth: '2px' }}
          disabled={buttonIsLoading}
          onClick={handleSubmit}
        >
          Demander mon devis ->
        </Button>
      </div>
    );
  };

  const handleFieldChange = e => {
    if (e.target.name === 'rgpd') {
      setFormData({
        ...formData,
        [e.target.name]: formData.rgpd === 0 ? 1 : 0
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    }
  };

  return (
    <Row className="">
      <Col lg={12} className="text-start pe-lg-5 pt-4 pt-lg-0">
        <img
          style={{ maxWidth: '75px' }}
          src={qwentyLight}
          alt="Logo Qwenty fond blanc"
        />
        <h2 className="pt-4">
          Obtenez votre devis personnalisé sous 24 heures !
        </h2>
      </Col>
      <Col lg={6} className="text-start">
        <Row>
          <Col lg={12} className="text-start mt-4" id="formcontact">
            {contactSent ? (
              <IconAlert variant="success">
                <p className="mb-0">
                  Merci, votre demande de devis a bien été transmise à Qwenty.
                </p>
              </IconAlert>
            ) : (
              <>
                {errorForm && <FormAlert variant="danger" data={errorForm} />}
                <Form>
                  <Row className="mb-4 g-3">
                    <Form.Group as={Col} controlId="formGridfirstname">
                      <Form.Label>
                        Prénom <span className="required">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Votre prénom"
                        name="firstname"
                        value={formData.firstname}
                        onChange={handleFieldChange}
                        required
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridlastname">
                      <Form.Label>
                        Nom <span className="required">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Votre nom"
                        name="lastname"
                        value={formData.lastname}
                        onChange={handleFieldChange}
                        required
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-4 g-3">
                    <Form.Group as={Col} controlId="formGridoccupation">
                      <Form.Label>
                        Nom de votre entreprise{' '}
                        <span className="required">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Votre entreprise"
                        name="company"
                        value={formData.company}
                        onChange={handleFieldChange}
                        required
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-4 g-3">
                    <Form.Group
                      as={Col}
                      style={{ zIndex: 9 }}
                      controlId="formGridemail"
                    >
                      <Form.Label>
                        E-mail <span className="required">*</span>
                      </Form.Label>
                      <Form.Control
                        type="mail"
                        placeholder="Votre adresse email"
                        name="email"
                        value={formData.email}
                        onChange={handleFieldChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridphone">
                      <Form.Label>
                        <span className="d-none d-lg-block">
                          Votre numéro de téléphone
                        </span>
                        <span className="d-block d-lg-none">Téléphone</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Votre numéro de téléphone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleFieldChange}
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-4 g-3">
                    <Form.Group as={Col} controlId="formGridmessage">
                      <Form.Label>
                        Message <span className="required">*</span>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        name="message"
                        placeholder="Écrivez votre message ici"
                        value={formData.message}
                        onChange={handleFieldChange}
                        rows={3}
                        required
                      />
                    </Form.Group>
                  </Row>

                  <Row className="mb-1 g-3">
                    <Form.Group as={Col} className="mb-0" id="formGridrgpd">
                      <Form.Check
                        type="checkbox"
                        name="rgpd"
                        checked={formData.rgpd === 1}
                        onChange={handleFieldChange}
                        label={
                          <small
                            style={{ lineHeight: 'normal', display: 'block' }}
                          >
                            En validant ce formulaire, vous acceptez que les
                            informations communiquées soient utilisées afin de
                            traiter correctement votre demande et conformément à
                            la{' '}
                            <a
                              href="https://qwenty.fr/politique-de-confidentialite"
                              target="_blank"
                              rel="noreferrer"
                              style={{
                                color: '#00394d',
                                textDecoration: 'underline'
                              }}
                            >
                              politique de confidentialité
                            </a>
                            .
                          </small>
                        }
                      />
                    </Form.Group>
                  </Row>
                  <GoogleReCaptchaProvider
                    reCaptchaKey="6LcN7GEmAAAAAKZAQHrDR11FvWmCPIpKXrti52Nf"
                    language="fr-FR"
                  >
                    <ReComponent />
                  </GoogleReCaptchaProvider>
                </Form>
              </>
            )}
          </Col>
        </Row>
      </Col>
      {!contactSent && (
        <Col lg={6} className="text-center mt-5 mt-lg-0">
          <img
            className="w-75"
            src={quoteUsers}
            alt="Demander un devis à Qwenty"
          />
        </Col>
      )}
    </Row>
  );
};

ContactForm.propTypes = {
  questionsHistoryStat: PropTypes.array
};

export default ContactForm;
