import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Section from 'components/common/Section';
import PropTypes from 'prop-types';

const SimulatorHeading = () => {
  return (
    <Section className="pt-4 pt-md-5 pt-lg-6 pb-0 d-none" id="header-simulator">
      <h1>
        Problème site Wordpress ?<br />
        Trouvons une solution{' '}
        <span className="highlight-yellow">rapidement</span> !
      </h1>
      <Row className="align-items-center justify-content-center mt-3">
        <Col xl={12} lg={12}>
          <p className="mb-3">
            Répondez à ce questionnaire pour trouver rapidement une solution à
            votre problème de site Wordpress.
            <br />
            <b>
              Notre équipe attentive et disponible pourra vous aider dans les
              plus brefs délais.
            </b>
          </p>
        </Col>
      </Row>
    </Section>
  );
};

SimulatorHeading.propTypes = {
  formulas: PropTypes.array,
  showLeadForm: PropTypes.any
};

export default SimulatorHeading;
