import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Container, Navbar } from 'react-bootstrap';
import handleNavbarTransparency from 'helpers/handleNavbarTransparency';
import AppContext from 'context/Context';
import logoQwenty from 'assets/img/logo-fond-clair.png';

const NavbarStandard = () => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const [navbarCollapsed] = useState(true);

  useEffect(() => {
    window.addEventListener('scroll', handleNavbarTransparency);
    return () => window.removeEventListener('scroll', handleNavbarTransparency);
  }, []);

  return (
    <Navbar
      expand="lg"
      className={classNames('navbar-standard navbar-theme box-shadow', {
        'bg-100': !navbarCollapsed && isDark,
        'bg-dark': !navbarCollapsed && !isDark
      })}
    >
      <Container className="px-0 px-lg-3">
        <Navbar.Brand
          onClick={() => {
            window.location.href = 'https://docteurwordpress.fr';
          }}
          className="me-0 cursor-pointer"
        >
          <img
            className="logo-qwenty"
            alt="Logo Docteur Wordpress - Réparer mon site internet"
            src={logoQwenty}
          />
        </Navbar.Brand>
        <a
          href="https://docteurwordpress.fr"
          style={{ position: 'relative', top: '3px' }}
        >
          <Button
            size="sm"
            variant="falcon-default"
            className="me-2 px-2 px-lg-3"
          >
            Revenir au site
          </Button>
        </a>
      </Container>
    </Navbar>
  );
};

export default NavbarStandard;
