import { Col, Row } from 'react-bootstrap';
import React from 'react';
import laura from 'assets/img/laura-duboeuf.webp';
import gatien from 'assets/img/gatien.webp';
import team from 'assets/img/team-qwenty.webp';

const ContactCta = () => {
  return (
    <Row className="mt-0 mb-0 justify-content-center">
      <Col lg={12} className="text-start">
        <h2 className="font-title-bold pb-2 text-center text-lg-start">
          Rencontrez l'équipe Qwenty{' '}
          <img
            style={{ maxWidth: '95px' }}
            className="ms-lg-2 pt-3 pb-md-1 ps-md-1 ps-lg-0 pt-md-0"
            src={team}
            alt="Équipe Qwenty"
          />
        </h2>
        <Row className="mt-2">
          <Col lg={6} className="text-start mt-3">
            <div className="contact">
              <Row className="align-items-center">
                <Col md={5} className="text-center text-lg-start">
                  <img
                    src={laura}
                    className="w-50 w-md-100 pb-3 pb-lg-0"
                    alt="Laura Qwenty"
                  />
                </Col>
                <Col md={7} className="text-center text-md-start">
                  <h4>Laura</h4>
                  <p>Cheffe de projet et développeuse web</p>
                  <p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      className="me-1"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M9.366 10.682a10.556 10.556 0 0 0 3.952 3.952l.884-1.238a1 1 0 0 1 1.294-.296 11.422 11.422 0 0 0 4.583 1.364 1 1 0 0 1 .921.997v4.462a1 1 0 0 1-.898.995c-.53.055-1.064.082-1.602.082C9.94 21 3 14.06 3 5.5c0-.538.027-1.072.082-1.602A1 1 0 0 1 4.077 3h4.462a1 1 0 0 1 .997.921A11.422 11.422 0 0 0 10.9 8.504a1 1 0 0 1-.296 1.294l-1.238.884zm-2.522-.657l1.9-1.357A13.41 13.41 0 0 1 7.647 5H5.01c-.006.166-.009.333-.009.5C5 12.956 11.044 19 18.5 19c.167 0 .334-.003.5-.01v-2.637a13.41 13.41 0 0 1-3.668-1.097l-1.357 1.9a12.442 12.442 0 0 1-1.588-.75l-.058-.033a12.556 12.556 0 0 1-4.702-4.702l-.033-.058a12.442 12.442 0 0 1-.75-1.588z"
                        fill="rgba(255,216,153,1)"
                      />
                    </svg>
                    +33 07 68 87 91 16
                  </p>
                  <p className="mb-0">
                    <small>Du lundi au vendredi de 09h à 17h30</small>
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={6} className="text-start mt-3">
            <div className="contact">
              <Row className="align-items-center">
                <Col md={5} className="text-center text-md-start">
                  <img
                    src={gatien}
                    className="w-50 w-md-100 pb-3 pb-lg-0"
                    alt="Gatien Qwenty"
                  />
                </Col>
                <Col md={7} className="text-center text-md-start">
                  <h4>Gatien</h4>
                  <p>Directeur et responsable créatif et technique</p>
                  <p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      className="me-1"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M9.366 10.682a10.556 10.556 0 0 0 3.952 3.952l.884-1.238a1 1 0 0 1 1.294-.296 11.422 11.422 0 0 0 4.583 1.364 1 1 0 0 1 .921.997v4.462a1 1 0 0 1-.898.995c-.53.055-1.064.082-1.602.082C9.94 21 3 14.06 3 5.5c0-.538.027-1.072.082-1.602A1 1 0 0 1 4.077 3h4.462a1 1 0 0 1 .997.921A11.422 11.422 0 0 0 10.9 8.504a1 1 0 0 1-.296 1.294l-1.238.884zm-2.522-.657l1.9-1.357A13.41 13.41 0 0 1 7.647 5H5.01c-.006.166-.009.333-.009.5C5 12.956 11.044 19 18.5 19c.167 0 .334-.003.5-.01v-2.637a13.41 13.41 0 0 1-3.668-1.097l-1.357 1.9a12.442 12.442 0 0 1-1.588-.75l-.058-.033a12.556 12.556 0 0 1-4.702-4.702l-.033-.058a12.442 12.442 0 0 1-.75-1.588z"
                        fill="rgba(255,216,153,1)"
                      />
                    </svg>
                    +33 07 49 55 53 01
                  </p>
                  <p className="mb-0">
                    <small>Du lundi au vendredi de 09h à 17h30</small>
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ContactCta;
