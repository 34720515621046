import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Section from 'components/common/Section';
import PropTypes from 'prop-types';
import Offers from './Offers';
import ContactCta from './ContactCta';
import ContactForm from './ContactForm';
import qwentyLight from 'assets/img/qwenty-light.webp';
import jeff from 'assets/img/jeff.webp';
import standout from 'assets/img/standout.png';
import natAmr from 'assets/img/nat-amr.webp';
import seabase from 'assets/img/seabase.webp';
import references from 'assets/img/references-qwenty.webp';

const Formulas = ({ formulas, questionsHistoryStat }) => {
  return (
    formulas && (
      <>
        <Offers formulas={formulas} />
        <Section id="qwenty" bg="light" className="pt-5 pb-4 pb-lg-6">
          <img
            style={{ maxWidth: '75px' }}
            src={qwentyLight}
            alt="Logo Qwenty fond blanc"
          />
          <h2 className="pt-4">
            Qwenty - La meilleure solution pour votre projet web
          </h2>
          <p className="pt-2">
            Qwenty répond à toutes les exigences des meilleures agences de
            développement web, et bien plus encore... Voyez par vous-mêmes :
          </p>
          <div className="d-block mt-4"></div>
          <h3 className="question-title">68 clients accompagnés</h3>
          <div className="d-block mt-2"></div>
          <h3 className="question-title">167% de croissance depuis 2020</h3>
          <div className="d-block mt-2"></div>
          <h3 className="question-title">85% de clients par recommandations</h3>
          <div className="d-block mt-2"></div>
          <h3 className="question-title">
            <a
              href="https://www.google.com/search?q=qwenty"
              rel="noreferrer"
              target="_blank"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 256 262"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="xMidYMid"
                style={{
                  position: 'relative',
                  bottom: '3px',
                  marginRight: '7px'
                }}
              >
                <path
                  d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
                  fill="#4285F4"
                />
                <path
                  d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
                  fill="#34A853"
                />
                <path
                  d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"
                  fill="#FBBC05"
                />
                <path
                  d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
                  fill="#EB4335"
                />
              </svg>
            </a>
            4,9/5 : note que nos clients nous ont attribuée{' '}
          </h3>{' '}
          <Row className="pt-2 pt-lg-5">
            <Col className="pt-2 pt-lg-0 pb-3 pb-lg-0" md={6}>
              <div className="gradient-1">
                <div className="gradient-title">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="me-1"
                    style={{ position: 'relative', bottom: '1px' }}
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-.997-6l7.07-7.071-1.414-1.414-5.656 5.657-2.829-2.829-1.414 1.414L11.003 16z"
                      fill="rgba(0,57,77,1)"
                    />
                  </svg>{' '}
                  Solution intuitive
                </div>
                <p className="pt-2">
                  Qwenty vous donne les clés en main pour créer un site de la
                  manière la plus intuitive possible. Notre équipe suit
                  l’évolution de vos besoins quotidiennement pour que vous
                  puissiez vous concentrer sur l’essentiel.
                </p>
              </div>
            </Col>
            <Col className="pb-3 pb-lg-0" md={6}>
              <div className="gradient-1">
                <div
                  className="gradient-title"
                  style={{
                    backgroundImage: 'linear-gradient(90deg,#ade4d5,#ffd899)'
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="me-0"
                    style={{ position: 'relative', bottom: '1px' }}
                  >
                    <path d="M7.941 18c-.297-1.273-1.637-2.314-2.187-3a8 8 0 1 1 12.49.002c-.55.685-1.888 1.726-2.185 2.998H7.94zM16 20v1a2 2 0 0 1-2 2h-4a2 2 0 0 1-2-2v-1h8zm-3-9.995V6l-4.5 6.005H11v4l4.5-6H13z" />
                  </svg>{' '}
                  Création libérée
                </div>
                <p className="pt-2">
                  Nous mettons un point d’honneur à laisser libre cours à votre
                  créativité ! Nous gérons les contraintes techniques, pour que
                  vous puissiez enfin disposer d’un site qui vous ressemble. La
                  seule limite est votre imagination.
                </p>
              </div>
            </Col>
            <Col md={12} className="pb-lg-4"></Col>
            <Col className="pb-3 pb-lg-0" md={6}>
              <div className="gradient-1">
                <div
                  className="gradient-title"
                  style={{
                    backgroundImage: 'linear-gradient(90deg,#fbd4da,#ade4d5)'
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="me-0"
                    style={{ position: 'relative', bottom: '1px' }}
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M13 10h7l-9 13v-9H4l9-13z" />
                  </svg>{' '}
                  Référencement optimisé
                </div>
                <p className="pt-2">
                  Nous pensons votre site avec les meilleures stratégies SEO en
                  tête, et ce depuis le jour 1 de notre collaboration. Nous
                  assurons un référencement optimal et durable, afin que vous
                  puissiez prétendre aux premières places des résultats de
                  recherche Google !
                </p>
              </div>
            </Col>
            <Col className="pb-3 pb-lg-0" md={6}>
              <div className="gradient-1">
                <div
                  className="gradient-title"
                  style={{
                    backgroundImage: 'linear-gradient(22deg,#fbd4da,#ffd899)'
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="me-0"
                    style={{ position: 'relative', bottom: '1px' }}
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M18 8h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h2V7a6 6 0 1 1 12 0v1zm-7 7.732V18h2v-2.268a2 2 0 1 0-2 0zM16 8V7a4 4 0 1 0-8 0v1h8z" />
                  </svg>{' '}
                  Sécurité assurée
                </div>
                <p className="pt-2">
                  Nous vous proposons une solution sécurisée et nous assurons
                  que vous, votre site et vos visiteurs soient protégés en
                  ligne. Votre site, comme celui de nos dizaines d’autres
                  clients, est sécurisé par nos soins et nos experts en la
                  matière.
                </p>
              </div>
            </Col>
            <Col md={12} className="pb-lg-4"></Col>
            <Col className="pb-3 pb-lg-0" md={6}>
              <div className="gradient-1">
                <div
                  className="gradient-title"
                  style={{
                    backgroundImage: 'linear-gradient(75deg,#ffd899,#ade4d5)'
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="me-0"
                    style={{ position: 'relative', bottom: '1px' }}
                  >
                    <path d="M14.121 10.48a1 1 0 0 0-1.414 0l-.707.706a2 2 0 1 1-2.828-2.828l5.63-5.632a6.5 6.5 0 0 1 6.377 10.568l-2.108 2.135-4.95-4.95zM3.161 4.468a6.503 6.503 0 0 1 8.009-.938L7.757 6.944a4 4 0 0 0 5.513 5.794l.144-.137 4.243 4.242-4.243 4.243a2 2 0 0 1-2.828 0L3.16 13.66a6.5 6.5 0 0 1 0-9.192z" />
                  </svg>{' '}
                  Accompagnement
                </div>
                <p className="pt-2">
                  Même si nous travaillons pour le digital, nous sommes une
                  entreprise gérée par des humains, tournée vers l’humain. Nous
                  vous parlons d’individu à individu, avec un seul objectif :
                  votre satisfaction.
                </p>
              </div>
            </Col>
            <Col className="pb-3 pb-lg-0" md={6}>
              <div className="gradient-1">
                <div
                  className="gradient-title"
                  style={{
                    backgroundImage: 'linear-gradient(32deg,#ade4d5,#fbd4da)'
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="me-0"
                    style={{ position: 'relative', bottom: '1px' }}
                  >
                    <path d="M9.33 11.5h2.17A4.5 4.5 0 0 1 16 16H8.999L9 17h8v-1a5.578 5.578 0 0 0-.886-3H19a5 5 0 0 1 4.516 2.851C21.151 18.972 17.322 21 13 21c-2.761 0-5.1-.59-7-1.625L6 10.071A6.967 6.967 0 0 1 9.33 11.5zM4 9a1 1 0 0 1 .993.883L5 10V19a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-9a1 1 0 0 1 1-1h2zm9.646-5.425L14 3.93l.354-.354a2.5 2.5 0 1 1 3.535 3.536L14 11l-3.89-3.89a2.5 2.5 0 1 1 3.536-3.535z" />
                  </svg>{' '}
                  Entreprise engagée
                </div>
                <p className="pt-2">
                  Les hommes et les femmes qui constituent notre équipe
                  partagent tous la volonté de s’engager dans un changement
                  durable, de participer à notre échelle à la création d’un
                  monde meilleur.
                </p>
              </div>
            </Col>
            <Col md={12} className="mt-0 mt-lg-6">
              <Row className="align-items-center">
                <Col md={4} lg={3}>
                  <img
                    className="w-100"
                    src={jeff}
                    alt="Jean-François Paillas Standout France"
                  />
                </Col>
                <Col md={8} lg={9} className="pt-3 pt-lg-0">
                  <p>
                    « Nous avons contacté Qwenty pour mettre en place de
                    nouvelles fonctionnalités sur notre site internet. La
                    qualité des réalisations, la réactivité et l’accompagnement
                    ont été excellents du premier contact à la livraison finale
                    ! »
                  </p>
                  <Row className="align-items-center pt-0">
                    <Col xs={2} lg={1}>
                      <img
                        className="w-100"
                        src={standout}
                        alt="Standout Agence communication"
                      />
                    </Col>
                    <Col xs={10} lg={11} className="ps-0 ps-lg-2">
                      <p className="mb-0 ref-small">Jean-François Paillas</p>
                      <p className="mb-0 ref-small">
                        Co-fondateur – StandOut France (agence de Communication
                        – Toulouse)
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col md={12} className="mt-5 mt-lg-6 mb-3">
              <Row className="align-items-center">
                <Col md={4} lg={3}>
                  <img
                    className="w-100"
                    src={natAmr}
                    alt="Nathalie Amr Seabase Egypte"
                  />
                </Col>
                <Col md={8} lg={9} className="pt-3 pt-lg-0">
                  <p>
                    « La rencontre avec Qwenty nous a décidé à créer notre
                    propre système de réservation sur mesure adapté à nos
                    besoins. Nous sommes très satisfaits non seulement du
                    résultat final mais aussi de la relation de travail avec
                    Qwenty à l’écoute, réactif, mobilisé sur nos problématiques.
                    »
                  </p>
                  <Row className="align-items-center pt-0">
                    <Col xs={2} lg={1}>
                      <img
                        className="w-100"
                        src={seabase}
                        alt="Sea Base Égypte"
                      />
                    </Col>
                    <Col xs={10} lg={11} className="ps-0 ps-lg-2">
                      <p className="mb-0 ref-small">Amr et Nathalie</p>
                      <p className="mb-0 ref-small">
                        Fondateurs – Sea Base (croisières de plongées en Mer
                        Rouge – Égypte)
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col md={12} className="mt-4 mt-lg-6">
              <img src={references} className="w-100" alt="Références Qwenty" />
            </Col>
          </Row>
        </Section>
        <Section className="text-center pt-4 pt-lg-5 contact-cta pb-lg-5 pb-4">
          <ContactCta />
        </Section>
        <Section
          id="contact-form"
          bg="light"
          className="text-center pt-lg-4 pt-0"
        >
          <ContactForm questionsHistoryStat={questionsHistoryStat} />
        </Section>
        <Section
          id="footer-qwenty"
          className="py-3"
          style={{ backgroundColor: '#FFD899' }}
        >
          <Row>
            <Col xs={12}>
              <small>
                <p>
                  * Les montants sont donnés à titre indicatif et n'engagent pas
                  Qwenty. Une étude approfondie de votre besoin est nécessaire
                  pour estimer précisément votre projet qui peut, suivant votre
                  besoin précis, sortir des limites de la fourchette budgétaire
                  affichée sur cette page.
                </p>
                <p className="mb-0">
                  © {new Date().getFullYear()} - Qwenty SAS - 4 Quai Koch 67000
                  Strasbourg - SIRET 89319227800024 -{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://qwenty.fr/mentions-legales"
                  >
                    Mentions légales
                  </a>{' '}
                  -{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://qwenty.fr/cgv"
                  >
                    CGV
                  </a>{' '}
                  -{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://qwenty.fr/politique-de-confidentialite"
                  >
                    RGPD
                  </a>
                </p>
              </small>
            </Col>
          </Row>
        </Section>
      </>
    )
  );
};

Formulas.propTypes = {
  simulator: PropTypes.any,
  setQuestion: PropTypes.any,
  formulas: PropTypes.array,
  setFormulas: PropTypes.any,
  setQuestionsHistory: PropTypes.any,
  setProgress: PropTypes.any,
  procedureCategories: PropTypes.array,
  questionsHistoryStat: PropTypes.array,
  setQuestionsHistoryStat: PropTypes.any,
  statisticId: PropTypes.any,
  selectedCategory: PropTypes.any,
  setSelectedCategory: PropTypes.func,
  selectedFormula: PropTypes.any,
  setSelectedFormula: PropTypes.func,
  selectedExample: PropTypes.any,
  setSelectedExample: PropTypes.func
};

export default Formulas;
