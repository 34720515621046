import { Button, Col, Row } from 'react-bootstrap';
import React from 'react';
import PropTypes from 'prop-types';
import Section from '../common/Section';

const Offers = ({ formulas }) => {
  return (
    <>
      <Section className="py-0 d-none" id="header-simulator">
        <Row className="mt-4 justify-content-center">
          <Col lg={12}>
            {formulas.map((formula, index) => (
              <Row
                key={index}
                className="formulas-container align-items-center mb-4"
              >
                <Col lg={7}>
                  <div className="offer-block">
                    <h3 className="formula-title mb-3">{formula.title}</h3>
                    <p
                      dangerouslySetInnerHTML={{ __html: formula.content }}
                    ></p>
                    <Row className="d-lg-none pt-2">
                      <Col xs={12}>
                        <span className="highlight-yellow price px-0 py-2">
                          {formula.price}*
                        </span>
                      </Col>
                      <Col xs={12} className="mt-3">
                        <Button
                          size="sm"
                          variant="falcon-default"
                          className="ms-0 d-block d-lg-inline"
                          onClick={() => {
                            document
                              .getElementById('contact-form')
                              .scrollIntoView();
                          }}
                        >
                          Obtenir mon devis personnalisé ->
                        </Button>
                      </Col>
                    </Row>
                    <p className="mb-2 pt-2 d-none d-lg-block">
                      <span className="highlight-yellow price px-3 py-2">
                        {formula.price}*
                      </span>
                      <Button
                        size="sm"
                        variant="falcon-default"
                        className="ms-2 d-block d-lg-inline"
                        onClick={() => {
                          document
                            .getElementById('contact-form')
                            .scrollIntoView();
                        }}
                      >
                        Obtenir mon devis personnalisé ->
                      </Button>
                    </p>
                  </div>
                </Col>
                <Col key={index} lg={5} className="px-5 d-none d-lg-block">
                  {formula.logo_url !== undefined &&
                    formula.logo_url !== null && (
                      <img src={formula.logo_url} className="w-100" />
                    )}
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
        <Row className="mt-2 mb-3 justify-content-center">
          <Col lg={12} className=" cursor-pointer compare-link">
            <p>
              <Button
                size="sm"
                variant="falcon-default"
                className=""
                onClick={() => {
                  document.getElementById('qwenty').scrollIntoView();
                }}
              >
                En savoir plus
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  className="ms-1"
                  style={{ position: 'relative', bottom: '1px' }}
                >
                  <path
                    d="M13 16.172l5.364-5.364 1.414 1.414L12 20l-7.778-7.778 1.414-1.414L11 16.172V4h2v12.172z"
                    fill="rgba(0,57,77,1)"
                  />
                </svg>
              </Button>
            </p>
          </Col>
        </Row>
      </Section>
    </>
  );
};

Offers.propTypes = {
  formulas: PropTypes.array
};

export default Offers;
